import React from "react"
import styled from "styled-components"
import { labelColor } from "mill/utils/colors"
export const RadioButtons = styled.div`
  margin-bottom: 3rem;

  label {
    display: flex;
    font-size: 1.8rem;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    color: ${labelColor};
    font-weight: normal;

    span {
      font-weight: normal;
    }
  }

  > div > div {
    margin-top: 1.5rem;

    label {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
    }
  }

  input {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 1rem;
    margin-left: 0.5px;
    margin-top: 0.2rem;

    &:focus {
      outline: none;
    }
  }
`

export const TeamSelectors = styled.div`
  margin-left: 3.4rem;
  margin-bottom: 3rem;
`
